import React from "react"
import styled from "@emotion/styled"
import { keyframes, SerializedStyles } from "@emotion/core"

const Button = styled.button`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 5px;
  color: white;
  font-family: "Open Sans";
  font-size: 16px;
  height: 42px;
  line-height: 22px;
  margin-top: 37px;
  margin-bottom: 20px;
  width: 250px;
  cursor: pointer;
  transition: box-shadow 1s;
  &.disabled {
    cursor: initial;
    background: gray;
    box-shadow: none;
  }
`

const LoadingSpinnerKeyframe = keyframes`
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
`

const Loading = styled.div`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 5px;
  color: white;
  font-family: "Open Sans";
  font-size: 16px;
  height: 42px;
  line-height: 22px;
  margin-top: 37px;
  width: 250px;
  margin-bottom: 20px;
`

const Spinner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  & > div {
    animation: ${LoadingSpinnerKeyframe} 1s linear infinite;
    border-radius: 50%;
    border: 2px solid #6fcf97;
    border-top-color: transparent;
    box-sizing: content-box;
    height: 10px;
    left: 50%;
    position: absolute;
    top: calc(50%);
    width: 10px;
  }
`

type Props = {
  text: string
  loading: boolean
  className?: string
  disabled?: boolean
  buttonCSS?: SerializedStyles
}

const ButtonSubmit = ({
  loading,
  text,
  buttonCSS,
  className = "",
  disabled = false,
}: Props) => (
  <>
    {loading ? (
      <Loading css={buttonCSS}>
        <Spinner>
          <div></div>
        </Spinner>
      </Loading>
    ) : (
      <Button
        css={buttonCSS}
        className={`${className} ${disabled ? "disabled" : ""}`}
        type="submit"
        disabled={disabled}
      >
        {text}
      </Button>
    )}
  </>
)

export default ButtonSubmit
